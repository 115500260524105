#div_annexure a{
    color: blue;
}

#div_annexure table {
    border-collapse: collapse ; /* Remove default table cell spacing */
    margin-bottom: 20px ;
    border: 1px solid #000 !important; /* Add border to cells */
}

#div_annexure th, td {
    border: 1px solid #000 !important; /* Add border to cells */
    text-align: left !important;
    padding: 8px !important;
}

#div_annexure  th {
    background-color: #f2f2f2 !important; /* Background color for header cells */
}

#div_annexure  tr:nth-child(even) {
    background-color: #f9f9f9 !important;/* Alternate row background color */
}

/* Optional: Hover effect on table rows */
#div_annexure  tr:hover {
    background-color: #e0e0e0 !important;
}

#div_annexure ol {
    list-style-type: decimal !important; /* Use numbers as the list item marker */
    margin-left: 20px !important; /* Add left margin for indentation */
    padding-left: 10px !important; /* Add left padding for additional space */
}

#div_annexure li {
    margin-bottom: 8px !important; /* Add space between list items */
}