@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.spinner-container-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: relative;
}

.spinner-inner {
  width: 80%;
  height: 80%;
  border: 6px solid transparent;
  border-top: 6px solid #f3f3f3; /* Light grey (same as the background) */
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
}