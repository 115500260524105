.larger-icon {
  width: 1.5em;
  height: 1.5em;
}

.gap {
  gap: 20px;
}

.col-lg-4:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 767px) {
  .contactus {
    display: flex !important;
    flex-direction: column !important;
  }
}
