.suggestions-container {
    border: 1px solid #ccc;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .suggestion-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .suggestion-item:last-child {
    border-bottom: none;
  }
  
  .suggestion-item:hover {
    background-color: #f5f5f5;
  }
  
  .suggestion-item.active {
    background-color: #e5e5e5;
    font-weight: bold;
  }
  .suggestions-inner{
    padding: 7px;
  }