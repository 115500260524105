.bottom-nav {
    position: fixed;
    background: #fff;
    background: #fff;
    bottom: 80px;
    padding: 5px;
    border-radius: 40px;
    box-shadow: 0 3px 9px rgba(37,0,0,.2);
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    z-index: 51;
  }
  .bottom-nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style-type: none;
  }
  .p-0 {
    padding: 0 !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .bottom-nav ul li a {
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 600;
    color: #131939;
    transition: all .3s linear;
    position: relative;
  }
  .bottom-nav ul li a img {
    max-width: 100%;
    height: 15px;
    margin: 0 auto;
    display: table;
  }
  .bottom-nav ul li a span {
    display: table;
    text-align: center;
    margin: 0 auto;
  }
  .bottom-nav ul li.active a {
    height: 60px;
    width: 60px;
    background: #22543d;
  }
  .bottom-nav ul li.active a, .bottom-nav ul li a .badge {
    color: #fff;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom-nav ul li.active a img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    width: 40px;
    max-width: 40px;
    height: auto;
  }
  *, a:active, a:focus, a:hover {
    text-decoration: none;
  }
  a {
    text-decoration: none !important;
  }
  img {
    display: block;
  }
  .bottom-nav ul li.active a span, .pro-lst-blks ul li .titlebx {
    display: none;
  }
  *, a:active, a:focus, a:hover {
    text-decoration: none;
  }

  .navButtonCls{
    color: #fff;
    text-decoration: none;
  }